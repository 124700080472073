<template>
<div>
    <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Select Token 1</label>
          <v-select
          v-model="token1select"
          label="source"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="token1list"
            :searchable="false"
          >
           <template #option="{ source,icon }">
                  <b-avatar size="lg" :src="icon" />
                  <span class="ml-50"> {{ source }}</span>
                </template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Select Token 2</label>
          <v-select
          v-model="token2select"
          label="source"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="token2list"
            :searchable="false"
          >
           <template #option="{ source,icon }">
                  <b-avatar size="lg" :src="icon" />
                  <span class="ml-50"> {{ source }}</span>
                </template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
        <div class="text-center mt-2">
         <b-button
         block
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="compareTokens"
    >
    <feather-icon
    icon="SearchIcon"/>
      Search
    </b-button>
    </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
    <b-row class="match-height">
      <b-col cols="12"  v-if="showSpinner">
          <div class="text-center text-success">
        <b-spinner  style="width: 3rem; height: 3rem;"/>
        </div>
      </b-col>
    <b-col cols="12" v-if="cambio==1">
            <apex-line-area :chartTitle="'Daily Total Volume'" :cseries="seriesVolume"
        :labels="labelsVolume" :key="cambio" :numerograph="0"/>

    </b-col>
    <!--b-col cols="12" v-if="cambio1==1">
        <apex-line-area :chartTitle="'Total Value Locked'" :cseries="seriesValueLocked"
        :labels="labelsValueLocked" :key="cambio1" :numerograph="0"/>
    </b-col>
    <b-col cols="12" v-if="cambio2==1">
        <apex-line-area :chartTitle="'Total Transactions'" :cseries="seriestransactions"
        :labels="labelsTransactions" :key="cambio2" :numerograph="0"/>
    </b-col-->
  </b-row>
</div>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,BButton,BSpinner,BAvatar 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive"
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
import ApexLineAreaChart from "@/components/charts/apex-chart/ApexLineAreaChart.vue"
import ApexLineArea from "@/components/charts/apex-chart/ApexLine&Area.vue"

export default {
    directives:{Ripple},
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,BButton,ApexMultipeBarChart,BSpinner,BAvatar,ApexLineAreaChart,ApexLineArea
  },
  data(){
    return{
      showSpinner:false,
        token1select:"",
        token1list:[
{source:"Raydium",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png"},
{source:"Orca",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png"},
{source:"Serum",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png"},
{source:"Aldrin",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp/logo.png"},
{source:"Step",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png"},
{source:"Whirlpool",icon:"https://raw.githubusercontent.com/solscanofficial/public-logos/main/whirlpool.svg"},
{source:"Lifinity",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp/logo.svg"},
{source:"Dust",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg"},
{source:"$Forge",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds/logo.png"}
],
        token2select:"",
        token2list:[
{source:"Raydium",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png"},
{source:"Orca",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png"},
{source:"Serum",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png"},
{source:"Aldrin",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp/logo.png"},
{source:"Step",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png"},
{source:"Whirlpool",icon:"https://raw.githubusercontent.com/solscanofficial/public-logos/main/whirlpool.svg"},
{source:"Lifinity",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp/logo.svg"},
{source:"Dust",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg"},
{source:"$Forge",icon:"https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds/logo.png"}

],
        labelsVolume:[],
        seriesVolume:[],
        labelsValueLocked:[],
        seriesValueLocked:[],
        labelsTransactions:[],
        seriestransactions:[],
        cambio:0,
        cambio1:0,
        cambio2:0
    }
  },
  methods:{
    compareTokens:function(){
      this.cambio=0
      this.cambio1=0
      this.cambio2=0
       const formData={
      tokenOne:this.token1select.source,
      tokenTwo:this.token2select.source
  };
        if (this.token1select=="" || this.token2select=="") {
           this.$swal({
        title: 'Error!',
        text: ' Select two tokens!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        }
        else if(this.token1select.source==this.token2select.source){
           this.$swal({
        title: 'Error!',
        text: ' Tokens are the Same',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        }
        else{
             this.showSpinner=true
       this.getVolume(formData)
       //this.getValueLocked(formData)
       //this.getTotalTransactions(formData)  
        }
    },
    getVolume:function(formData){
           const vm=this
            vm.seriesVolume=[]
            vm.labelsVolume=[]
            const axios = require("axios");
  var arre=[]
  var arre1=[]
  var arr2=[]
    const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
  }
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/tkceve",formData,config).then(function (response) {
    for (let x = 0; x < response.data.data1.length; x++) {
      arre.push(response.data.data1[x])
    }
    for (let x = 0; x < response.data.data2.length; x++) {
      arre1.push(response.data.data2[x])
    }
    for (let x = 0; x < response.data.labels.length; x++) {
          const date = new Date(response.data.labels[x]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
      arr2.push(d)
          
    }
       vm.seriesVolume.push({name:vm.token1select.source,type:'line',data:arre})
       vm.seriesVolume.push({name:vm.token2select.source,type:'line',data:arre1})
       vm.labelsVolume=arr2
       vm.showSpinner=false
       vm.cambio=1
    })
    .catch((err) => {
      console.log(err);
    });
    },
     getValueLocked:function(formData){
          const vm=this
            vm.seriesValueLocked=[]
            vm.labelsValueLocked=[]
            const axios = require("axios");
  var arre=[]
  var arre1=[]
  var arr2=[]
    const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
  }
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/tkceld",formData,config).then(function (response) {  
    for (let x = 0; x < response.data.data1.length; x++) {
      arre.push(response.data.data1[x])
    }
    for (let x = 0; x < response.data.data2.length; x++) {
      arre1.push(response.data.data2[x])
    }
    for (let x = 0; x < response.data.labels.length; x++) {
          const date = new Date(response.data.labels[x]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
      arr2.push(d)
          
    }
       vm.seriesValueLocked.push({name:vm.token1select.source,type:'line',data:arre})
       vm.seriesValueLocked.push({name:vm.token2select.source,type:'line',data:arre1})
       vm.labelsValueLocked=arr2
       vm.cambio1=1
    })
    .catch((err) => {
      console.log(err);
    });
     },
      getTotalTransactions:function(formData){
           const vm=this
            vm.seriestransactions=[]
            vm.labelsTransactions=[]
            const axios = require("axios");
  var arre=[]
  var arre1=[]
  var arr2=[]
    const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
  }
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/tkcetn",formData,config).then(function (response) { 
    for (let x = 0; x < response.data.data1.length; x++) {
      arre.push(response.data.data1[x])
    }
    for (let x = 0; x < response.data.data2.length; x++) {
      arre1.push(response.data.data2[x])
    }
    for (let x = 0; x < response.data.labels.length; x++) {
          const date = new Date(response.data.labels[x]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
      arr2.push(d)     
    }
       vm.seriestransactions.push({name:vm.token1select.source,type:'line',data:arre})
       vm.seriestransactions.push({name:vm.token2select.source,type:'line',data:arre1})
       vm.labelsTransactions=arr2
       vm.cambio2=1
    })
    .catch((err) => {
      console.log(err);
    });

      }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
